import { apiIns } from "@config/api.config";
import { IQueryParams } from "@interfaces/common.interface";
import { queryStringMapper } from "utils";

export const EmployeeScheduleService = {
  getAll: async (
    queryParams?: IQueryParams,
    start: string,
    end: string
  ): Promise<any> =>
    await apiIns.get(
      `employee/schedules` +
        queryStringMapper(queryParams) +
        `from_date=${start}&to_date=${end}`
    ),
};

export const ScheduleService = {
  getSchedules: async (
    queryParams: IQueryParams,
    sessionId?: string
  ): Promise<any> =>
    await apiIns.get(
      `schedules/` + queryStringMapper(queryParams) + `session_id=${sessionId}`
    ),

  generalSubmit: async (payload: any): Promise<any> =>
    await apiIns.post(`schedules`, payload),

  updateGeneralSubmit: async (id: string, payload: any): Promise<any> =>
    await apiIns.put(`schedules/` + id, payload),

  getScheduleById: async (id: string): Promise<any> =>
    await apiIns.get(`schedules/` + id),

  deleteScheduleById: async (id: string): Promise<any> =>
    await apiIns.delete(`schedules/` + id),

  createSchedule: async (payload: any): Promise<any> =>
    await apiIns.post(`schedules`, payload),

  scheduleAssignShifts: async (payload: any): Promise<any> =>
    await apiIns.post(`schedules/assign-shifts`, payload),

  employeeList: async (id: string, queryParams: IQueryParams): Promise<any> =>
    await apiIns.get(
      `schedules/employee-list/` + id + queryStringMapper(queryParams)
    ),

  assignEmployees: async (payload: any): Promise<any> =>
    await apiIns.post(`schedules/assign-employees/`, payload),

  conflictEmployees: async (
    employeeId: string,
    scheduleId: string
  ): Promise<any> =>
    await apiIns.get(
      `schedules/employee-conflicting-shifts/${employeeId}?schedule_id=${scheduleId}`
    ),

  calenderView: async (
    scheduleId: string,
    start_date: string,
    end_date: string
  ): Promise<any> =>
    await apiIns.get(
      `schedules/calendar-view/${scheduleId}?start_date=${start_date}&end_date=${end_date}`
    ),

  calendarViewShiftDetails: async (
    scheduleId: string,
    start_date: string
  ): Promise<any> =>
    await apiIns.get(`schedules/orientation/${scheduleId}?date=${start_date}`),

  calendarViewShiftAssign: async (payload: any): Promise<any> =>
    await apiIns.post(`schedules/repeat`, payload),

  schedulePublish: async (
    id: string,
    payload: { status: boolean }
  ): Promise<any> => await apiIns.put(`schedules/publish/${id}`, payload),
};
