import { IQueryParams } from "@interfaces/common.interface";
import { weekArray } from "@pages/Schedule/Schedule.data";
import { IShift } from "@pages/ShiftManagement/RosterOverride/RosterOverride";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ScheduleService } from "@services/apis/shiftManagement/Schedule.service";
import { ShiftService } from "@services/apis/shiftManagement/Shift.service";
import { topProgress } from "@services/utils/topProgress.service";
import { deepCopy } from "utils";
import { getDatesInRange } from "utils/date.utils";
import { ToastService } from "./../../@services/utils/toastr.service";

interface IUserInfo {
	shifts: IShift[];
	shiftsObject: any;
	schedule: any;
	scheduleList: any;
	isLoading: boolean;
	isEmployeeLoading: boolean;
	isSubmitting: boolean;
	stepDoneList: {
		general: boolean;
		shift: boolean;
		schedule_employees: boolean;
	};
	employeeList: any;
	assignEmployee: any;
	totalEmployeeInList: number;
	totalScheduleList: number;
	urlParamsValue: string;
	conflictEmployees: any[];
	calendarViewData: any;
	calendarViewSelectedDate: string;
	calendarViewShiftAssign: any;
	shiftDetails: any;
	view: "weekly" | "monthly";
}

const initialState: IUserInfo = {
	shifts: [],
	shiftsObject: {},
	schedule: {},
	scheduleList: [],
	employeeList: {},
	assignEmployee: [],
	isLoading: false,
	isEmployeeLoading: false,
	isSubmitting: false,
	stepDoneList: { general: false, shift: false, schedule_employees: false },
	totalEmployeeInList: 0,
	totalScheduleList: 0,
	urlParamsValue: "",
	conflictEmployees: [],
	calendarViewData: {},
	calendarViewSelectedDate: "",
	calendarViewShiftAssign: {},
	shiftDetails: {},
	view: "weekly",
};

export const getShifts = createAsyncThunk("schedule/getShifts", () => {
	const response = ShiftService.activeShiftList();
	return response;
});

export const getShiftsDetails = createAsyncThunk(
	"schedule/getShiftsDetails",
	(id: string) => {
		const response = ShiftService.getById(id);
		return response;
	}
);

export const getSchedules = createAsyncThunk(
	"schedule/getSchedules",
	({
		queryParams,
		sessionId,
	}: {
		queryParams: IQueryParams;
		sessionId: string;
	}) => {
		const response = ScheduleService.getSchedules(queryParams, sessionId);

		return response;
	}
);

export const getScheduleById = createAsyncThunk(
	"schedule/getScheduleById",
	(id: string) => {
		const response = ScheduleService.getScheduleById(id);
		return response;
	}
);

export const deleteScheduleById = createAsyncThunk(
	"schedule/deleteScheduleById",
	(id: string) => {
		const response = ScheduleService.deleteScheduleById(id);
		return response;
	}
);

export const assignShift = createAsyncThunk(
	"schedule/assignShift",
	(payload: any) => {
		const response = ScheduleService.scheduleAssignShifts(payload);
		return response;
	}
);

export const scheduleEmployeeList = createAsyncThunk(
	"schedule/scheduleEmployeeList",
	({ id, queryParams }: { id: string; queryParams: IQueryParams }) => {
		const response = ScheduleService.employeeList(id, queryParams);
		return response;
	}
);

export const assignEmployees = createAsyncThunk(
	"schedule/assignEmployees",
	(payload: any) => {
		const response = ScheduleService.assignEmployees(payload);
		return response;
	}
);

export const conflictEmployees = createAsyncThunk(
	"schedule/conflictEmployees",
	({
		employeeId,
		scheduleId,
	}: {
		employeeId: string;
		scheduleId: string;
	}) => {
		const response = ScheduleService.conflictEmployees(
			employeeId,
			scheduleId
		);
		return response;
	}
);

export const calendarView = createAsyncThunk(
	"schedule/calenderView",
	({
		scheduleId,
		start_date,
		end_date,
	}: {
		scheduleId: string;
		start_date: string;
		end_date: string;
	}) => {
		const response = ScheduleService.calenderView(
			scheduleId,
			start_date,
			end_date
		);
		return response;
	}
);

export const calendarViewShiftDetails = createAsyncThunk(
	"schedule/calendarViewShiftDetails",
	({
		scheduleId,
		start_date,
	}: {
		scheduleId: string;
		start_date: string;
	}) => {
		const response = ScheduleService.calendarViewShiftDetails(
			scheduleId,
			start_date
		);
		return response;
	}
);

export const calendarViewShiftAssignOnDate = createAsyncThunk(
	"schedule/calendarViewShiftAssignOnDate",
	(payload: any) => {
		const response = ScheduleService.calendarViewShiftAssign(payload);
		return response;
	}
);

export const schedulePublish = createAsyncThunk(
	"schedule/schedulePublish",
	({ id, status }: { id: string; status: boolean }) => {
		const response = ScheduleService.schedulePublish(id, { status });
		return response;
	}
);

export const userSlice = createSlice({
	name: "schedule",
	initialState,
	reducers: {
		setSchedule: (state: any, action: PayloadAction<any>) => {
			state.schedule = action.payload;
		},
		setIsLoading: (state: any, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setIsSubmitting: (state: any, action: PayloadAction<boolean>) => {
			state.isSubmitting = action.payload;
		},
		setStepDoneList: (state: any, action: PayloadAction<any>) => {
			state.stepDoneList = action.payload;
		},
		setScheduleEmployeeList: (state: any, action: PayloadAction<any>) => {
			state.employeeList = action.payload;
		},
		setScheduleList: (state: any, action: PayloadAction<any>) => {
			state.scheduleList = action.payload;
		},
		setAssignEmployee: (state: any, action: PayloadAction<any>) => {
			state.assignEmployee = action.payload;
		},
		setUrlParamsValue: (state: any, action: PayloadAction<any>) => {
			state.urlParamsValue = action.payload;
		},
		setCalendarViewSelectedDate: (
			state: any,
			action: PayloadAction<any>
		) => {
			state.calendarViewSelectedDate = action.payload;
		},
		setView: (state: any, action: PayloadAction<any>) => {
			state.view = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getShifts.fulfilled, (state, { payload: res }) => {
			state.shifts = res.payload;
			return state;
		});

		// get shift details
		builder.addCase(getShiftsDetails.pending, (state) => {
			topProgress.show();
			state.isLoading = true;
			return state;
		});
		builder.addCase(getShiftsDetails.rejected, (state) => {
			topProgress.hide();
			state.isLoading = false;
			return state;
		});
		builder.addCase(
			getShiftsDetails.fulfilled,
			(state, { payload: res }) => {
				topProgress.hide();
				state.shiftDetails = res.payload;
				state.isLoading = false;
				return state;
			}
		);

		// get schedule
		// builder.addCase(getSchedules.pending, (state) => {
		//   state.isLoading = true;
		//   return state;
		// }),
		builder.addCase(getSchedules.rejected, (state) => {
			state.isLoading = false;
			return state;
		});
		builder.addCase(getSchedules.fulfilled, (state, { payload: res }) => {
			state.isLoading = false;

			let modSchedule = deepCopy(res.payload?.schedules) || [];
			modSchedule = modSchedule.map((item: any, index: number) => {
				const orientations: any[] = [];

				let weekData: any[] = [];
				if (item.type === "Weekly") {
					weekData =
						getDatesInRange(
							item.effective_start_date,
							item.end_date
						) || [];
				}

				item.schedule_orientations.map((sItem: any, index: number) => {
					if (sItem?.recurrence_type) return;
					orientations.push(
						...(sItem?.schedule_orientation_shifts || [])
					);

					if (item.type === "Weekly") {
						sItem.name = weekData[index];
					} else {
						sItem.name = weekArray[sItem.day_index]?.name;
					}

					sItem.is_day_off = sItem.is_day_off || false;
					return sItem;
				});
				item.orientations = orientations;
				return item;
			});
			state.scheduleList = modSchedule;
			state.shifts = res?.payload?.shifts || {};
			state.totalScheduleList = res.meta.total;
			state.isLoading = false;

			return state;
		});

		// get schedule by id
		builder.addCase(getScheduleById.pending, (state) => {
			// state.isLoading = true;
			return state;
		});
		builder.addCase(getScheduleById.rejected, (state) => {
			state.isLoading = false;
			return state;
		});
		builder.addCase(
			getScheduleById.fulfilled,
			(state, { payload: res }) => {
				// Add user to the state array

				const orientations: any[] = [];

				const modSchedule = deepCopy(res.payload);

				modSchedule.month_id =
					new Date(modSchedule.effective_start_date).getMonth() + 1;

				modSchedule.schedule_orientations =
					modSchedule.schedule_orientations.map(
						(item: any, index: number) => {
							orientations.push(
								...(item?.schedule_orientation_shifts || [])
							);

							item.name = weekArray[item.day_index]?.name;
							item.is_day_off = item.is_day_off || false;
							return item;
						}
					);

				modSchedule.orientations = orientations;

				state.schedule = modSchedule;
				state.isLoading = false;

				return state;
			}
		);

		// schedule delete
		builder.addCase(deleteScheduleById.pending, (state) => {
			topProgress.show();
			return state;
		});
		builder.addCase(deleteScheduleById.rejected, (state) => {
			topProgress.hide();
			return state;
		});
		builder.addCase(deleteScheduleById.fulfilled, (state) => {
			topProgress.hide();
			ToastService.success("Schedule Deleted Successfully!");
			// if delete then get call isLoading will be true
			state.isLoading = true;
			return state;
		});

		// assign shift
		builder.addCase(assignShift.pending, (state) => {
			state.isSubmitting = true;
			return state;
		});
		builder.addCase(assignShift.fulfilled, (state) => {
			ToastService.success("Shift assigned successfully!");
			state.isSubmitting = false;
			return state;
		});
		builder.addCase(assignShift.rejected, (state) => {
			state.isSubmitting = false;
			return state;
		});

		// schedule employee list
		builder.addCase(scheduleEmployeeList.pending, (state) => {
			state.isEmployeeLoading = true;
			return state;
		});
		builder.addCase(scheduleEmployeeList.rejected, (state) => {
			state.isEmployeeLoading = false;
			return state;
		});
		builder.addCase(
			scheduleEmployeeList.fulfilled,
			(state, { payload: res }) => {
				state.isEmployeeLoading = false;
				const modPayload = deepCopy(res.payload);
				modPayload.map((item: any) => {
					item.assigned = item.id ? true : false;
				});
				state.employeeList = modPayload;
				state.totalEmployeeInList = res.meta.total;

				return state;
			}
		);

		// assign employee
		builder.addCase(assignEmployees.pending, (state) => {
			state.isSubmitting = true;
			return state;
		});
		builder.addCase(assignEmployees.rejected, (state) => {
			state.isSubmitting = false;
			return state;
		});
		builder.addCase(assignEmployees.fulfilled, (state) => {
			ToastService.success("Employee assigned successfully!");
			state.isSubmitting = false;
			return state;
		});

		// conflict employee
		builder.addCase(conflictEmployees.pending, (state) => {
			topProgress.show();
			state.isLoading = true;
			return state;
		});
		builder.addCase(conflictEmployees.rejected, (state) => {
			topProgress.hide();
			state.isLoading = false;
			return state;
		});
		builder.addCase(
			conflictEmployees.fulfilled,
			(state, { payload: res }) => {
				topProgress.hide();

				state.conflictEmployees = res.payload;
				state.isLoading = false;
			}
		);

		// calender view
		builder.addCase(calendarView.pending, (state) => {
			topProgress.show();
			state.isLoading = true;
			return state;
		});
		builder.addCase(calendarView.rejected, (state) => {
			topProgress.hide();
			state.isLoading = false;
			return state;
		});
		builder.addCase(calendarView.fulfilled, (state, { payload: res }) => {
			topProgress.hide();

			state.calendarViewData = res.payload;
			state.shiftsObject = res.payload.shifts;

			state.isLoading = false;

			return state;
		});

		// calender view shift details
		builder.addCase(calendarViewShiftDetails.pending, (state) => {
			topProgress.show();
			state.isLoading = true;
			return state;
		});
		builder.addCase(calendarViewShiftDetails.rejected, (state) => {
			topProgress.hide();
			state.isLoading = false;
			return state;
		});
		builder.addCase(
			calendarViewShiftDetails.fulfilled,
			(state, { payload: res }) => {
				topProgress.hide();

				state.calendarViewShiftAssign = res.payload;
				state.isLoading = false;

				return state;
			}
		);

		// calender view shift assign
		builder.addCase(calendarViewShiftAssignOnDate.pending, (state) => {
			state.isSubmitting = true;
			topProgress.show();
			return state;
		});
		builder.addCase(calendarViewShiftAssignOnDate.rejected, (state) => {
			topProgress.hide();
			state.isSubmitting = false;
			return state;
		});
		builder.addCase(calendarViewShiftAssignOnDate.fulfilled, (state) => {
			ToastService.success("Shifts assigned successfully!");
			topProgress.hide();
			state.isSubmitting = false;
			state.isLoading = true;
			return state;
		});

		// schedule publish
		builder.addCase(schedulePublish.pending, (state) => {
			topProgress.show();
			return state;
		});
		builder.addCase(schedulePublish.rejected, (state) => {
			topProgress.hide();
			return state;
		});
		builder.addCase(schedulePublish.fulfilled, (state) => {
			topProgress.hide();
			return state;
		});
	},
});

export const {
	setSchedule,
	setIsLoading,
	setStepDoneList,
	setAssignEmployee,
	setScheduleList,
	setScheduleEmployeeList,
	setUrlParamsValue,
	setCalendarViewSelectedDate,
	setIsSubmitting,
	setView,
} = userSlice.actions;

export default userSlice.reducer;
