import ForgetPassword from "@assets/images/forgetPassword.png";
import ResetPassword from "@assets/images/resetPassword.png";
import SignIn from "@assets/images/signIn.png";
import { ENV } from "@config/ENV.config";
import { IFileDelete, IQueryParams } from "@interfaces/common.interface";
import { IEmployee } from "@pages/EmployeesModule/Employees/Employees";
import { GlobalService } from "@services/apis/Global.service";
import { ToastService } from "@services/utils/toastr.service";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const queryStringMapper = (params?: IQueryParams): string => {
	if (!params) return "";
	let url: string = "?";
	if (params.page) url = url + `page=${params.page}`;
	if (params.limit) url = url + `&limit=${params.limit}`;
	if (params.status) url = url + `&status=${params.status}`;
	if (params.session_id) url = url + `&session_id=${params.session_id}`;
	if (isArray(params.filters) || isObject(params.filters))
		url = url + `&filters=${JSON.stringify(params.filters)}&`;
	return url;
};

export const generateId = () => Math.floor(100000 + Math.random() * 900000);
export const generateUUID = () => uuidv4();

// export const useTranslationUtils = () => {
//   const { t } = useTranslation();

//   const t = (key: any) => {
//     return t(key);
//   };

//   return {
//     t
//   };
// };

type slug = (value: string) => string;

export const slugGenerate: slug = (value) => {
	return value
		.toString()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase()
		.trim()
		.replace(/\s+/g, "-")
		.replace(/[^\w-]+/g, "")
		.replace(/--+/g, "-");
};

export const fileRemove = ({
	id,
	path,
	preFunctionality,
	thenFunction,
	catchFunction,
	finallyFunction,
}: IFileDelete) => {
	if (!path) return;
	preFunctionality && preFunctionality();
	GlobalService.delete(path, id)
		.then((res) => {
			ToastService.success("Deleted Successfully");
			thenFunction && thenFunction();
		})
		.catch((err) => {
			ToastService.error(err.message);
			catchFunction && catchFunction();
		})
		.finally(() => finallyFunction && finallyFunction());
};

export const trimString = (
	str: string,
	maxLength: number = 12,
	noDot = false
) => {
	if (str?.length <= maxLength) {
		return str; // No need to trim
	} else {
		const trimmedString = str?.slice(0, maxLength) + (noDot ? "" : "...");
		// let isTrimmed = true;

		// const toggleTrim = () => {
		//   isTrimmed = !isTrimmed;
		//   return isTrimmed ? trimmedString : str;
		// };

		return trimmedString;
	}
};

export const statusUpdate = (
	id: string,
	newStatus: string,
	modulePath: string,
	thenFunction?: Function
) => {
	const status = newStatus === "Active" ? true : false;

	GlobalService.statusUpdate(modulePath, id, {
		is_active: status,
	})
		.then((res) => {
			ToastService.success("Status Updated Successfully");
			thenFunction && thenFunction();
		})
		.catch((err) => ToastService.error(err.message));
};

export const nameMapper = (object: IEmployee | any): string => {
	if (object?.first_name && object?.last_name) {
		return object?.first_name + " " + object?.last_name;
	} else if (object?.last_name) {
		return object?.last_name;
	}
	return "--";
};

export const onlyUrlPars = (url: string) => {
	if (!url) return "";
	return url.split("?")[0];
};

export const date2Time = (date: string | Date) => {
	return new Date(date).toLocaleTimeString("en-IT", { hour12: false });
};

export const isValidDate = (date: any) => {
	if (date) {
		return !isNaN(new Date(date).getTime());
	}
	return false;
};

export const isObject = (value: any) => {
	return (
		value &&
		typeof value === "object" &&
		value.constructor === Object &&
		Object.keys(value)?.length
	);
};

export const isArray = (value: any) => {
	return value && Array.isArray(value) && value?.length;
};

export const ObjectFilter: any = (raw: any, allowed: string[]) => {
	const obj: any = new Object();

	allowed.map((key) => {
		if (raw[key]) {
			obj[key] = raw[key];
		}
	});

	return obj;
};

export const hexToLowOpacityRGBA = (
	hex: string,
	opacity: number
): string | null => {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i;
	hex = hex?.replace(shorthandRegex, (m, r, g, b, a) => {
		return r + r + g + g + b + b + (a ? a + a : "");
	});

	const result =
		/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
	if (!result) {
		return null;
	}

	const [_, r, g, b] = result;

	return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(
		b,
		16
	)}, ${opacity})`;
};

export const useImageSwitchCase = (): string => {
	const [imageUrl, setImageUrl] = useState<string>("");
	const location = useLocation();

	useEffect(() => {
		const url = location.pathname;

		switch (url) {
			case "/reset-password":
				setImageUrl(ForgetPassword);
				break;
			case "/new-password":
				setImageUrl(ResetPassword);
				break;
			default:
				setImageUrl(SignIn);
				break;
		}
	}, [location]);

	return imageUrl;
};

export function formatDateToYYYYMMDD(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
	const day = String(date.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}

export const deepCopy: any = (data: any) => {
	if (Array.isArray(data)) {
		return data.map((item) => deepCopy(item));
	} else if (isObject(data)) {
		const copiedObj: any = {};
		for (const key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				copiedObj[key] = deepCopy(data[key]);
			}
		}
		return copiedObj;
	} else {
		return data;
	}
};

export const numberLocalization = (number: any) => {
	try {
		let temp = number;
		if (typeof number === "number") temp = JSON.stringify(number);

		const lg = localStorage.getItem("i18nextLng");

		if (lg === "bn" && temp) {
			return temp.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);
		}

		if (lg === "ar" && temp) {
			return temp.replace(/\d/g, (d: any) => "٩٨٧٦٥٤٣٢١٠"[d]);
		}

		return number;
	} catch (err) {
		ENV.env === "development" && console.log(err);
		return number;
	}
};

export const findIndex = (data: any[], find: any) => {
	if (!isArray(data)) return 0;
	return data.findIndex((item) => item === find);
};

export const capitalize = (text: string) => {
	if (!text) return;
	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const delay = (time: number = 1000) => {
	return new Promise((resolve) => setTimeout(resolve, time));
};

export const cacheClear = async () => {
	await caches
		.keys()
		.then((keyList) =>
			Promise.all(keyList.map((key) => caches.delete(key)))
		);
};

export const searchParamsToObject = (searchParams: any) => {
	const params: any = {};
	searchParams.forEach(
		(item: string, key: string) => item !== "null" && (params[key] = item)
	);
	return params;
};

interface FilterQueries {
	[key: string]: any[] | any;
}

export const generateFilterData = (filterQueries: FilterQueries): any => {
	const filterData: any = {};

	Object.keys(filterQueries).forEach((key: string) => {
		if (Array.isArray(filterQueries[key]) || filterQueries[key]) {
			filterData[key] = Array.isArray(filterQueries[key])
				? filterQueries[key].map((item: any) => item?.id)
				: filterQueries[key];
		}
	});

	return filterData;
};

export const extraZero = (number: string | number) => {
	if (Number(number) <= 9) {
		return "0" + number;
	}
	return number;
};
