import { integrationSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	API_TOKENS,
	APIS,
	ATTENDANCE_DEVICES,
	ATTENDANCE_LOCATIONS,
	ATTENDANCES_DEV,
} from "./path-name.route";

export const integrationsRoutes: IRouteList[] = [
	{
		path: ATTENDANCES_DEV,
		component: lazy(
			() => import("@pages/Settings/Integrations/Attendance/Attendance")
		),
		meta: {
			action: integrationSettingsAcl.attendanceDevice.parent.action,
			resource: integrationSettingsAcl.attendanceDevice.parent.resource,
		},
		children: [
			{
				path: ATTENDANCE_DEVICES,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Integrations/Attendance/Tabs/AttendanceDevice/AttendanceDevices"
						)
				),
				meta: {
					action: integrationSettingsAcl.attendanceDevice
						.attendanceDevices.getAll.action,
					resource:
						integrationSettingsAcl.attendanceDevice
							.attendanceDevices.getAll.resource,
				},
			},

			{
				path: ATTENDANCE_LOCATIONS,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Integrations/Attendance/Tabs/AttendanceLocation/AttendanceLocation"
						)
				),
				meta: {
					action: integrationSettingsAcl.attendanceDevice
						.attendanceLocations.getAll.action,
					resource:
						integrationSettingsAcl.attendanceDevice
							.attendanceLocations.getAll.resource,
				},
			},
		],
	},
	{
		path: APIS,
		component: lazy(() => import("@pages/Settings/Integrations/Apis/Apis")),
		meta: integrationSettingsAcl.apis.parent,
		children: [
			{
				path: API_TOKENS,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Integrations/Apis/Tabs/ApiTokens/ApiTokens"
						)
				),
				meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
			},
		],
	},
];
