import { reportAcl } from "@acl/modules/report.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  ALL_SUMMARY,
  APPLICATION_STATUS,
  ATTENDANCE_REPORT,
  BALANCE_REPORT,
  EMPLOYEE_LOG,
  EMPLOYEE_SUMMARY,
  INDIVIDUAL_ATTENDANCE_REPORT,
  INDIVIDUAL_SUMMARY,
  LEAVE,
  REPORTS,
  SCHEDULES_OVERVIEW,
  STATISTICS,
  SUMMARY,
} from "./path-name.route";

export const reportRoutes: IRouteList[] = [
  {
    path: REPORTS,
    component: lazy(() => import("@pages/Reports/Reports")),
    meta: {
      action: reportAcl.parent.action,
      resource: reportAcl.parent.resource,
    },
  },

  {
    path: REPORTS + ALL_SUMMARY,
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Employee/EmployeeAllSummary/EmployeeAllSummary"
        )
    ),
    meta: {
      action: reportAcl.employees.allSummary.action,
      resource: reportAcl.employees.allSummary.resource,
    },
  },
  {
    path: REPORTS + INDIVIDUAL_SUMMARY,
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Employee/EmployeeIndividualSummary/EmployeeIndividualSummary"
        )
    ),
    meta: {
      action: reportAcl.employees.individualDetails.action,
      resource: reportAcl.employees.individualDetails.resource,
    },
  },
  {
    path: REPORTS + ATTENDANCE_REPORT + "/*",
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Attendance/AttendanceAllEmployees/AttendanceAllEmployees"
        )
    ),
    meta: {
      action: reportAcl.attendances.allEmployee.action,
      resource: reportAcl.attendances.allEmployee.resource,
    },
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Attendance/AttendanceAllEmployees/Tab/AttendanceAllEmploySummaryReport/AttendanceAllEmploySummary"
            )
        ),
        meta: {
          action: reportAcl.attendances.allEmployee.action,
          resource: reportAcl.attendances.allEmployee.resource,
        },
      },
      {
        path: STATISTICS,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Attendance/AttendanceAllEmployees/Tab/AttendanceStatistics/AttendanceStatistics"
            )
        ),
        meta: {
          action: reportAcl.attendances.allEmployee.action,
          resource: reportAcl.attendances.allEmployee.resource,
        },
      },
    ],
  },

  {
    path: REPORTS + INDIVIDUAL_ATTENDANCE_REPORT,
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Attendance/IndividualAttendance/IndividualAttendanceReport"
        )
    ),
    meta: {
      action: reportAcl.attendances.individualEmployee.action,
      resource: reportAcl.attendances.individualEmployee.resource,
    },
  },
  {
    path: REPORTS + EMPLOYEE_LOG,
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Attendance/AttendanceLog/AttendanceLogReport"
        )
    ),
    meta: {
      action: reportAcl.attendances.dailySummary.action,
      resource: reportAcl.attendances.dailySummary.resource,
    },
  },
  {
    path: REPORTS + EMPLOYEE_SUMMARY,
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Attendance/AttendanceSummary/AttendanceSummaryReport"
        )
    ),
    meta: {
      action: reportAcl.attendances.allSummary.action,
      resource: reportAcl.attendances.allSummary.resource,
    },
  },

  // --------leave route

  {
    path: REPORTS + LEAVE + APPLICATION_STATUS + "/*",
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Leave/LeaveApplicationStatus/LeaveApplicationStatusReport"
        )
    ),
    meta: {
      action: reportAcl.leaves.applicationStatus.action,
      resource: reportAcl.leaves.applicationStatus.resource,
    },
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveApplicationStatus/Tab/ApplicationStatusSummery/ApplicationStatusSummeryReport"
            )
        ),

        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
      {
        path: STATISTICS,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveApplicationStatus/Tab/LeaveStatusStatistics/LeaveStatusStatictics"
            )
        ),
        meta: {
          action: reportAcl.leaves.applicationStatus.action,
          resource: reportAcl.leaves.applicationStatus.resource,
        },
      },
    ],
  },

  {
    path: REPORTS + LEAVE + BALANCE_REPORT + "/*",
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Leave/LeaveBalanceReport/LeaveBalanceReport"
        )
    ),
    meta: {
      action: reportAcl.leaves.balance.action,
      resource: reportAcl.leaves.balance.resource,
    },
    children: [
      {
        path: SUMMARY,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveBalanceReport/Tab/BalanceReportSummery/BalanceReportSummery"
            )
        ),
        meta: {
          action: reportAcl.leaves.balance.action,
          resource: reportAcl.leaves.balance.resource,
        },
      },
      {
        path: STATISTICS,
        component: lazy(
          () =>
            import(
              "@pages/Reports/AllReports/Leave/LeaveBalanceReport/Tab/BalanceReportStatistics/BalanceReportStatistics"
            )
        ),
      },
    ],
  },

  // --------schedule route
  {
    path: REPORTS + SCHEDULES_OVERVIEW,
    component: lazy(
      () =>
        import(
          "@pages/Reports/AllReports/Schedule/SchedulesOverview/SchedulesOverview"
        )
    ),
    meta: {
      action: reportAcl.schedules.allEmployee.action,
      resource: reportAcl.schedules.allEmployee.resource,
    },
  },

  // ],
];
