import { apiIns } from "@config/api.config";
import { IQueryParams } from "@interfaces/common.interface";
import { isObject, queryStringMapper } from "utils";

export const ReportService = {
  getAttendanceReport: async (
    searchQuery: IQueryParams,
    filterQueries: any,
    from_date: string,
    to_date: string
  ): Promise<any> =>
    await apiIns.get(
      `reports/attendances${queryStringMapper(searchQuery)}
      ${from_date ? "&from_date=" + from_date : ""}
      ${to_date ? "&to_date=" + to_date : ""}
      ${
        isObject(filterQueries)
          ? "&filters=" + JSON.stringify(filterQueries)
          : ""
      }
      `
    ),

  getEmployeePerformance: async (
    id: string,
    from_date: string,
    to_date: string
  ): Promise<any> =>
    await apiIns.get(
      `reports/attendances/${id}?${from_date ? "from_date=" + from_date : ""}
              ${from_date ? "&to_date=" + to_date : ""}
             
              `
    ),

  getEmployees: async (
    searchQuery: IQueryParams,
    filterQueries: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/employees${queryStringMapper(searchQuery)}${
        isObject(filterQueries)
          ? "&filters=" + JSON.stringify(filterQueries)
          : ""
      }`
    ),

  getEmployeeSummery: async (
    searchQuery: IQueryParams,
    filterQueries: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/employees/summary${queryStringMapper(searchQuery)}${
        isObject(filterQueries)
          ? "&filters=" + JSON.stringify(filterQueries)
          : ""
      }`
    ),

  getLeaveBalanceReport: async (searchQuery: IQueryParams): Promise<any> =>
    await apiIns.get(`reports/leave-balances${queryStringMapper(searchQuery)}`),

  getSchedules: async (
    searchQuery: IQueryParams,
    from_date: string,
    to_date: string,
    filters: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/schedules${queryStringMapper(searchQuery)}${
        from_date ? "from_date=" + from_date : ""
      }${to_date ? "&to_date=" + to_date : ""}
      ${isObject(filters) ? "&filters=" + JSON.stringify(filters) : ""}
        `
    ),

  getLeaveApplicationStatus: async (
    searchQuery: IQueryParams,

    filters: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/leave-applications/status-report${queryStringMapper(
        searchQuery
      )}${isObject(filters) ? "&filters=" + JSON.stringify(filters) : ""}`
    ),

  getReportOptions: async (): Promise<any> =>
    await apiIns.get(`reports/options`),
};

export const ReportAttendanceService = {
  getDailyAttendanceReport: async (
    searchQuery: IQueryParams,
    filterQueries: any,
    date: string
  ): Promise<any> =>
    await apiIns.get(
      `reports/daily-attendances${queryStringMapper(searchQuery)}
      ${date ? "&date=" + date : ""}
     
      ${
        isObject(filterQueries)
          ? "&filters=" + JSON.stringify(filterQueries)
          : ""
      }
      `
    ),
  getAttendanceSummery: async (
    searchQuery: IQueryParams,
    from_date: string,
    to_date: string,
    filterQueries: any
  ): Promise<any> =>
    await apiIns.get(
      `reports/attendance-summary${queryStringMapper(searchQuery)}
      ${from_date ? "&from_date=" + from_date : ""}${
        to_date ? "&to_date=" + to_date : ""
      }

      ${
        isObject(filterQueries)
          ? "&filters=" + JSON.stringify(filterQueries)
          : ""
      }
      `
    ),
};
