import { apiIns } from "@config/api.config";
import { IBatchIds, IQueryParams } from "@interfaces/common.interface";
import { IShift } from "@pages/ShiftManagement/RosterOverride/RosterOverride";
import { queryStringMapper } from "utils";

export interface IShiftPayload {
  id: string;
  name: string;
  short_name: string;
  start_time: string;
  end_time: string;
  break_time_minutes: number;
  min_working_hours: number;
  flexibility_minutes: number;
  no_earlier_minutes: number;
  allow_delay_minutes: number;
  no_later_then_minutes: number;
  over_time_threshold_minutes: number;
  is_active: boolean;
  color: string;
}

export const ShiftService = {
  getAll: async (queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`shifts` + queryStringMapper(queryParams)),

  getById: async (id: string): Promise<any> => await apiIns.get(`shifts/` + id),

  create: async (payload: IShift): Promise<any> =>
    await apiIns.post("shifts", payload),

  update: async (payload: IShiftPayload, shift_id: string): Promise<any> =>
    await apiIns.put("shifts/" + shift_id, payload),

  delete: async (shift_id: string): Promise<any> =>
    await apiIns.delete("shifts/" + shift_id),

  batchDelete: async (payload: IBatchIds): Promise<any> =>
    await apiIns.put("shifts/batch-remove", payload),

  activeShiftList: async (queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`shifts/active-list` + queryStringMapper(queryParams)),
};
