import { lazy } from "react";
import { IRouteList } from "./list.route";
import { NOTIFICATIONS } from "./path-name.route";

const Notifications = lazy(
  () => import("@pages/Settings/Notifications/Notifications")
);

export const NotificationsRoutes: IRouteList[] = [
  {
    path: NOTIFICATIONS,
    component: Notifications,
  },
];
