import { hasPermitted } from "@acl/utils";
import AuthLayout from "@layouts/AuthLayout";
import { TopProgressCom } from "@services/utils/topProgress.service";
import { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { IRouteList, privateRouteList } from "./list.route";
import { DASHBOARD, LOGIN, RESETPASSWORD } from "./path-name.route";

const PrivateRoutes = () => {
	const routeList = (routes: IRouteList[]) => {
		return routes?.map((route, index: number) => {
			if (route.meta && !hasPermitted(route.meta)) return null;

			if (route.children && route.children?.length) {
				return (
					<Route
						path={route.path}
						element={
							route?.component ? (
								<Suspense fallback={<TopProgressCom />}>
									<route.component />
								</Suspense>
							) : (
								<>
									<Outlet />
								</>
							)
						}
						key={index}
					>
						{routeList(route.children)}
					</Route>
				);
			}

			return (
				<Route
					key={index}
					path={route.path}
					element={
						route?.redirect ? (
							<Navigate to={route.redirect} />
						) : (
							<Suspense fallback={<TopProgressCom />}>
								{route.component ? (
									<route.component />
								) : (
									<Outlet />
								)}
							</Suspense>
						)
					}
				/>
			);
		});
	};

	return (
		<Routes>
			<Route element={<AuthLayout />}>
				{routeList(privateRouteList)}
				<Route path="*" element={<Navigate to={DASHBOARD} />} />
				<Route path={LOGIN} element={<Navigate to={DASHBOARD} />} />
				<Route
					path={RESETPASSWORD}
					element={<Navigate to={DASHBOARD} />}
				/>
			</Route>
		</Routes>
	);
};

export { PrivateRoutes };
