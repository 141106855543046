import Icon from "@components/Icon/Icon";
import HeaderSKeleton from "@components/Skeleton/HeaderSKeleton";
import SidebarSkeleton from "@components/Skeleton/SidebarSkeleton";
import { useSkeleton } from "@services/utils/skeleton.service";
import { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import "./Sidebar/Sidebar.css";

interface ILayout {
  showSidebar: boolean;
  setShowSidebar: Function;
}
export const LayoutContext = createContext({} as ILayout);
const AuthLayout: React.FC = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(
    JSON.parse(sessionStorage.getItem("expandSidebar") || "true")
  );
  const { startSkeleton, stopSkeleton } = useSkeleton();

  useEffect(() => {
    stopSkeleton();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("expandSidebar", expandSidebar);
  }, [expandSidebar]);

  return (
    <LayoutContext.Provider value={{ showSidebar, setShowSidebar }}>
      <div className="auth-layout">
        {screen.width > 1023 && !startSkeleton ? (
          <div
            onClick={() => setExpandSidebar(!expandSidebar)}
            className={`flex items-center justify-center w-12 h-12 bg-white rounded-full absolute border-2 border-primary-500 top-[45%] ${
              expandSidebar ? "left-[207px]" : "left-[78px]"
            } z-50 cursor-pointer custom-transition`}
          >
            <Icon
              className={`text-3xl text-neutral-400 leading-0 ${
                expandSidebar ? "rotate-0" : "rotate-180"
              } transition-transform  ease-in-out duration-500`}
              name={"keyboard_arrow_left"}
            />
          </div>
        ) : null}
        <div
          className={`auth-wrapper grid 
          ${
            expandSidebar
              ? "lg:grid-cols-[232px,auto] "
              : "lg:grid-cols-[102px,auto] "
          }
          md:grid-cols-[auto] xs:grid-cols-[auto] sm:grid-cols-[auto] grid-rows-[64px,auto] xs:h-screen custom-transition`}
        >
          <div
            style={{
              boxShadow:
                "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            }}
            className="header z-[2] "
          >
            <HeaderSKeleton show={startSkeleton} />
            <Header show={!startSkeleton} />
          </div>

          <div
            onClick={() => setShowSidebar(false)}
            className={`${
              showSidebar
                ? "fixed top-16 left-0 z-10 backdrop-blur-sm  w-screen h-screen"
                : "hidden"
            }
                
                ${expandSidebar ? "expand1" : "collapse1"}
                `}
          />
          <div
            style={{
              boxShadow:
                "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            }}
            className={`h-[${
              screen.height - 64 + "px"
            }] row-span-full z-[1] overflow-y-scroll no-scrollbar
            ${
              showSidebar
                ? "absolute animate-slide-right left-0 top-16 xs:w-auto lg:w-96 h-full z-[99] "
                : "sidebar sm:hidden lg:block xs:hidden md:hidden"
            }`}
          >
            <SidebarSkeleton show={startSkeleton} />
            <Sidebar
              show={!startSkeleton}
              expandSidebar={expandSidebar}
              setExpandSidebar={setExpandSidebar}
            />
          </div>

          <div className="md:w-screen sm:w-screen xs:w-full lg:w-full overflow-y-auto bg-white sm:h-auto xs:h-screen">
            <Outlet />
          </div>
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export default AuthLayout;
