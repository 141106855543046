import { defaultEmployeeAcl } from "@acl/modules/employee.data";
import { scheduleAcl } from "@acl/modules/schedule.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	EMPLOYEE_SCHEDULE,
	LIST_VIEW,
	ROSTER_FORM,
	SCHEDULE,
	SCHEDULE_DETAILS,
} from "./path-name.route";

const Schedule = lazy(() => import("@pages/Schedule/Schedule"));

export const ScheduleRoutes: IRouteList[] = [
	{
		path: SCHEDULE + "/*",
		component: Schedule,
		meta: scheduleAcl.parent,
		children: [
			// {
			//   path: ROSTERS,
			//   component: Roster,
			//   meta: {
			//     action: scheduleAcl.roster.getAll.action,
			//     resource: scheduleAcl.roster.getAll.resource,
			//   },
			// },
			// {
			//   path: FIXED_HOURS,
			//   component: FixedHour,
			//   meta: {
			//     action: scheduleAcl.fixedHour.getAll.action,
			//     resource: scheduleAcl.fixedHour.getAll.resource,
			//   },
			// },
			{
				path: LIST_VIEW,
				component: lazy(
					() => import("@pages/Schedule/ListView/ListView")
				),
				meta: scheduleAcl.schedules?.getAll,
			},
			{
				path: EMPLOYEE_SCHEDULE,
				component: lazy(
					() =>
						import(
							"@pages/Schedule/EmployeeSchedule/EmployeeSchedule"
						)
				),
				meta: {
					action: defaultEmployeeAcl.action,
					resource: defaultEmployeeAcl.resource,
				},
			},
		],
	},
	{
		path: SCHEDULE_DETAILS,
		component: lazy(
			() =>
				import(
					"@pages/Schedule/ListView/Components/ShiftDetailsCalendarView"
				)
		),
		meta: {
			action: defaultEmployeeAcl.action,
			resource: defaultEmployeeAcl.resource,
		},
		children: [],
	},
	{
		path: ROSTER_FORM,
		component: lazy(
			() => import("@pages/Schedule/ListView/RosterForm/RosterFormLayout")
		),
		meta: scheduleAcl.parent,
		children: [
			{ path: "*", redirect: "general" },
			{
				path: "general",
				component: lazy(
					() =>
						import(
							"@pages/Schedule/ListView/RosterForm/GeneralForm"
						)
				),
				meta: [
					scheduleAcl.schedules.create,
					scheduleAcl.schedules.update,
				],
				isIndex: true,
			},
			{
				path: "shift",
				component: lazy(
					() => import("@pages/Schedule/ListView/RosterForm/AddShift")
				),
				meta: [
					scheduleAcl.schedules.assignShifts,
					scheduleAcl.schedules.getCalendarView,
					scheduleAcl.schedules.repeatSchedule,
				],
			},
			{
				path: "schedule-employees",
				component: lazy(
					() =>
						import(
							"@pages/Schedule/ListView/RosterForm/AddEmployForm"
						)
				),
				meta: [scheduleAcl.schedules.assignEmployees],
			},
		],
	},
];
