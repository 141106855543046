export const statusOptions = [
	{
		label: "active",
		value: "Active",
	},
	{
		label: "inactive",
		value: "Inactive",
	},
];

export const sessionStatusOptions = [
	{
		label: "current",
		value: "Current",
	},
	{
		label: "closed",
		value: "Closed",
	},
];

export const fullMonth = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"Jun",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const shortMonth = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

type WeekStartDayOption = {
	name: string;
	value: number;
};

export const weekStartDayOptions: WeekStartDayOption[] = [
	{
		name: "saturday",
		value: 6,
	},
	{
		name: "sunday",
		value: 0,
	},
	{
		name: "monday",
		value: 1,
	},
	// {
	//   name: "tuesday",
	//   value: 2,
	// },
	// {
	//   name: "wednesday",
	//   value: 3,
	// },
	// {
	//   name: "thursday",
	//   value: 4,
	// },
	// {
	//   name: "friday",
	//   value: 5,
	// },
];
export const attendanceTypes = [
	{
		name: "Rysenova Web",
		value: "Rysenova Web",
	},
	{
		name: "Rysenova App",
		value: "Rysenova App",
	},
	{
		name: "Attendance Device",
		value: "Attendance Device",
	},
];
export const statusType = [
	{
		title: "all",
		value: "All",
	},
	{
		title: "active",
		value: "Active",
	},

	{
		title: "inactive",
		value: "Inactive",
	},
	{
		title: "pending",
		value: "Pending",
	},

	{
		title: "suspended",
		value: "Suspended",
	},

	{
		title: "archived",
		value: "Archived",
	},
];

export const daysOfWeekArr = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
