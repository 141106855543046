import { IAttendanceAcl } from "@acl/acl.interfaces";

export const attendanceAcl: IAttendanceAcl = {
  parent: {
    action: "attendances",
    resource: "attendances",
  },
  record: {
    getAll: { action: "attendanceRecords", resource: "attendances" },
    checkIn: { action: "checkIn", resource: "attendances" },
    checkOut: { action: "checkOut", resource: "attendances" },
    attendanceStatus: {
      action: "attendanceStatus",
      resource: "attendances",
    },
    batchAttendance: { action: "batchAttendance", resource: "attendances" },
  },
  summary: {
    action: "attendanceSummary",
    resource: "attendances",
  },
  attendanceRequest: {
    getAll: {
      action: "getRequestAttendanceList",
      resource: "attendances",
    },
  },
};
