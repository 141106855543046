import { DaysOfWeek, IMonth, IRosterType } from "@interfaces/roster.interface";

export const tabs = [
  { id: 1, label: "create_roster", editLabel: "edit_info" },
  { id: 2, label: "add_shifts", editLabel: "edit_shifts" },
  { id: 3, label: "add_employee", editLabel: "edit_employee_list" },
];
export const editModeTabs = [
  { id: 1, label: "edit_info" },
  { id: 2, label: "edit_shifts" },
  { id: 3, label: "edit_employee_list" },
];

export const rosterType: IRosterType[] = [
  { title: "monthly", value: "Monthly" },
  {
    title: "Weekly",
    value: "Weekly",
  },
];

export const months: IMonth[] = [
  { id: 1, value: "January" },
  { id: 2, value: "February" },
  { id: 3, value: "March" },
  { id: 4, value: "April" },
  { id: 5, value: "May" },
  { id: 6, value: "June" },
  { id: 7, value: "July" },
  { id: 8, value: "August" },
  { id: 9, value: "September" },
  { id: 10, value: "October" },
  { id: 11, value: "November" },
  { id: 12, value: "December" },
];

export const daysOfWeek: DaysOfWeek = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export const limitOption = [
  {
    id: "1",
    name: "limit",
    value: "10",
    label: "10_items",
  },
  {
    id: "2",
    name: "limit",
    value: "20",
    label: "20_items",
  },
  {
    id: "3",
    name: "limit",
    value: "30",
    label: "30_items",
  },
  {
    id: "4",
    name: "limit",
    value: "40",
    label: "40_items",
  },
];
export const week = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const fixedHourTabs = [
  { id: 1, label: "fixed_hour" },
  { id: 2, label: "shifts" },
  { id: 3, label: "employee" },
];
export const fixedHourEditModeTabs = [
  { id: 1, label: "info" },
  { id: 2, label: "shifts" },
  { id: 3, label: "employee_list" },
];

export const weekArray = [
  { id: 1, day: 0, name: "Sun", shift_id: [], is_day_off: false },
  { id: 2, day: 1, name: "Mon", shift_id: [], is_day_off: false },
  { id: 3, day: 2, name: "Tue", shift_id: [], is_day_off: false },
  { id: 4, day: 3, name: "Wed", shift_id: [], is_day_off: false },
  { id: 5, day: 4, name: "Thu", shift_id: [], is_day_off: false },
  { id: 6, day: 5, name: "Fri", shift_id: [], is_day_off: false },
  { id: 7, day: 6, name: "Sat", shift_id: [], is_day_off: false },
];
