import { apiIns } from "@config/api.config";
import {
	IAuthPasswordReset,
	IForgotPasswordPayload,
	ILoginPayload,
	IResetPasswordPayload,
} from "@interfaces/auth.interface";
export const AuthService = {
	login: async (payload: ILoginPayload): Promise<any> =>
		await apiIns.post("login", payload),
	loginInfo: async (): Promise<any> => await apiIns.get("system-config"),
	userInfo: async (): Promise<any> => await apiIns.get("user-info"),
	createUser: async (payload: any): Promise<any> =>
		await apiIns.post("users", payload),
	logout: async (): Promise<any> => await apiIns.get("logout"),
	authProfile: async (): Promise<any> => await apiIns.get("users/profile"),

	emailVerification: async (
		token: string,
		type: string | any
	): Promise<any> =>
		await apiIns.get("verify-email/" + token + `?type=${type}`),
	regenerateEmailVerificationLink: async (
		token: string,
		type: string | any
	): Promise<any> =>
		await apiIns.get("new-verification-link/" + token + `?type=${type}`),
	regeneratePasswordSetupLink: async (
		token: string,
		type: string | any
	): Promise<any> =>
		await apiIns.get("new-token-password/" + token + `?type=${type}`),
	updatePassword: async (
		token: string,
		type: string,
		payload: IResetPasswordPayload
	): Promise<any> =>
		await apiIns.put("new-password/" + token + `?type=${type}`, payload),

	passwordVerification: async (token: string): Promise<any> =>
		await apiIns.get("password-verification/" + token),
	authPasswordReset: async (payload: IAuthPasswordReset): Promise<any> =>
		await apiIns.put("users/password-reset", payload),

	forgotPassword: async (payload: IForgotPasswordPayload): Promise<any> =>
		await apiIns.post("forgot-password", payload),
	resetPassword: async (
		payload: IResetPasswordPayload,
		token: string
	): Promise<any> => await apiIns.put("password-reset/" + token, payload),

	session: async (): Promise<any> => await apiIns.get(`financial-sessions`),
};
