import Ae from "@assets/flags/ae.svg";
// import Af from "@assets/flags/af.svg";
// import Am from "@assets/flags/am.svg";
import Au from "@assets/flags/au.svg";
// import Az from "@assets/flags/az.svg";
import Bd from "@assets/flags/bd.svg";
import Bh from "@assets/flags/bh.svg";
// import Bn from "@assets/flags/bn.svg";
// import Bt from "@assets/flags/bt.svg";
// import Ca from "@assets/flags/ca.svg";
// import Cn from "@assets/flags/cn.svg";
// import Cy from "@assets/flags/cy.svg";
// import Ge from "@assets/flags/ge.svg";
// import Id from "@assets/flags/id.svg";
// import Il from "@assets/flags/il.svg";
// import In from "@assets/flags/in.svg";
// import Iq from "@assets/flags/iq.svg";
// import Ir from "@assets/flags/ir.svg";
// import Jo from "@assets/flags/jo.svg";
// import Jp from "@assets/flags/jp.svg";
// import Kg from "@assets/flags/kg.svg";
// import Kh from "@assets/flags/kh.svg";
// import Kp from "@assets/flags/kp.svg";
// import Kr from "@assets/flags/kr.svg";
import Kw from "@assets/flags/kw.svg";
// import Kz from "@assets/flags/kz.svg";
// import La from "@assets/flags/la.svg";
// import Lb from "@assets/flags/lb.svg";
// import Lk from "@assets/flags/lk.svg";
// import Mm from "@assets/flags/mm.svg";
// import Mn from "@assets/flags/mn.svg";
// import Mv from "@assets/flags/mv.svg";
// import My from "@assets/flags/my.svg";
// import Np from "@assets/flags/np.svg";
import Om from "@assets/flags/om.svg";
// import Ph from "@assets/flags/ph.svg";
// import Pk from "@assets/flags/pk.svg";
import Ps from "@assets/flags/ps.svg";
import Qa from "@assets/flags/qa.svg";
import Sa from "@assets/flags/sa.svg";
// import Sg from "@assets/flags/sg.svg";
import Sy from "@assets/flags/sy.svg";
// import Th from "@assets/flags/th.svg";
// import Tj from "@assets/flags/tj.svg";
// import Tl from "@assets/flags/tl.svg";
// import Tm from "@assets/flags/tm.svg";
// import Tw from "@assets/flags/tw.svg";
// import Us from "@assets/flags/us.svg";
// import Uz from "@assets/flags/uz.svg";
// import Vn from "@assets/flags/vn.svg";
import Ye from "@assets/flags/ye.svg";
import Icon from "@components/Icon/Icon";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@components/Popover/Popover";
import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { generateId } from "utils";

export interface ICountries {
  country: string;
  code?: string;
  iso?: string;
  flag?: string;
  maxLength?: number;
}

export const countryData: ICountries[] = [
  // { country: "Afghanistan", code: "+93", iso: "AF", flag: Af, maxLength: 9 },
  // { country: "Armenia", code: "+374", iso: "AM", flag: Am, maxLength: 10 },
  { country: "Australia", code: "+61", iso: "AU", flag: Au, maxLength: 9 },
  // { country: "Azerbaijan", code: "+994", iso: "AZ", flag: Az, maxLength: 8 },
  { country: "Bahrain", code: "+973", iso: "BH", flag: Bh, maxLength: 7 },
  { country: "Bangladesh", code: "+880", iso: "BD", flag: Bd, maxLength: 10 },
  // { country: "Bhutan", code: "+975", iso: "BT", flag: Bt, maxLength: 8 },
  // { country: "Brunei", code: "+673", iso: "BN", flag: Bn, maxLength: 7 },
  // { country: "Cambodia", code: "+855", iso: "KH", flag: Kh, maxLength: 11 },
  // { country: "China", code: "+86", iso: "CN", flag: Cn, maxLength: 10 },
  // { country: "Cyprus", code: "+357", iso: "CY", flag: Cy, maxLength: 10 },
  // { country: "Georgia", code: "+995", iso: "GE", flag: Ge, maxLength: 11 },
  // { country: "India", code: "+91", iso: "IN", flag: In, maxLength: 9 },
  // { country: "Indonesia", code: "+62", iso: "ID", flag: Id, maxLength: 12 },
  // { country: "Iran", code: "+98", iso: "IR", flag: Ir, maxLength: 10 },
  // { country: "Iraq", code: "+964", iso: "IQ", flag: Iq, maxLength: 9 },
  // { country: "Israel", code: "+972", iso: "IL", flag: Il, maxLength: 6 },
  // { country: "Japan", code: "+81", iso: "JP", flag: Jp, maxLength: 9 },
  // { country: "Jordan", code: "+962", iso: "JO", flag: Jo, maxLength: 10 },
  // { country: "Kazakhstan", code: "+7", iso: "KZ", flag: Kz, maxLength: 10 },
  { country: "Kuwait", code: "+965", iso: "KW", flag: Kw, maxLength: 7 },
  // { country: "Kyrgyzstan", code: "+996", iso: "KG", flag: Kg, maxLength: 8 },
  // { country: "Laos", code: "+856", iso: "LA", flag: La, maxLength: 10 },
  // { country: "Lebanon", code: "+961", iso: "LB", flag: Lb, maxLength: 8 },
  // { country: "Malaysia", code: "+60", iso: "MY", flag: My, maxLength: 7 },
  // { country: "Maldives", code: "+960", iso: "MV", flag: Mv, maxLength: 9 },
  // { country: "Mongolia", code: "+976", iso: "MN", flag: Mn, maxLength: 7 },
  // { country: "Myanmar", code: "+95", iso: "MM", flag: Mm, maxLength: 9 },
  // { country: "Nepal", code: "+977", iso: "NP", flag: Np, maxLength: 9 },
  // { country: "North Korea", code: "+850", iso: "KP", flag: Kp, maxLength: 14 },
  { country: "Oman", code: "+968", iso: "OM", flag: Om, maxLength: 10 },
  // { country: "Pakistan", code: "+92", iso: "PK", flag: Pk, maxLength: 9 },
  { country: "Palestine", code: "+970", iso: "PS", flag: Ps, maxLength: 6 },
  // { country: "Philippines", code: "+63", iso: "PH", flag: Ph, maxLength: 10 },
  { country: "Qatar", code: "+974", iso: "QA", flag: Qa, maxLength: 10 },
  {
    country: "Saudi Arabia",
    code: "+966",
    iso: "SA",
    flag: Sa,
    maxLength: 11,
  },
  // { country: "Singapore", code: "+65", iso: "SG", flag: Sg, maxLength: 7 },
  // { country: "South Korea", code: "+82", iso: "KR", flag: Kr, maxLength: 10 },
  // { country: "Sri Lanka", code: "+94", iso: "LK", flag: Lk, maxLength: 9 },
  { country: "Syria", code: "+963", iso: "SY", flag: Sy, maxLength: 6 },
  // { country: "Taiwan", code: "+886", iso: "TW", flag: Tw, maxLength: 7 },
  // { country: "Tajikistan", code: "+992", iso: "TJ", flag: Tj, maxLength: 11 },
  // { country: "Thailand", code: "+66", iso: "TH", flag: Th, maxLength: 9 },
  // { country: "Timor-Leste", code: "+670", iso: "TL", flag: Tl, maxLength: 14 },
  // { country: "Turkmenistan", code: "+993", iso: "TM", flag: Tm, maxLength: 14 },
  {
    country: "United Arab Emirates",
    code: "+971",
    iso: "AE",
    flag: Ae,
    maxLength: 6,
  },
  // { country: "Uzbekistan", code: "+998", iso: "UZ", flag: Uz, maxLength: 8 },
  // { country: "Vietnam", code: "+84", iso: "VN", flag: Vn, maxLength: 11 },
  { country: "Yemen", code: "+967", iso: "YE", flag: Ye, maxLength: 14 },
  // { country: "United States", code: "1", iso: "US", flag: Us, maxLength: 9 },
  // { country: "Canada", code: "+1", iso: "CA", flag: Ca, maxLength: 10 },
];

interface IInputProps {
  label: string | JSX.Element;
  placeholder?: string;
  onChange?: Function;
  onBlur?: Function;
  onClick?: Function;
  onFocus?: Function;
  iconRight?: JSX.Element;
  isDisabled?: boolean;
  errorText?: string | any;
  registerProperty?: any;
  helpText?: string | JSX.Element;
  ref?: React.Ref<HTMLInputElement>;
  id?: string;
  classNames?: string;
  flagValue: string | any;
  name: string;
  setAsFormValue: Function;
  setState?: Function;
  returnValue?: string;
  noMargin?: boolean;
  isRequired?: boolean;
}

const PhoneInput: React.FC<IInputProps> = ({
  label,
  placeholder,
  onChange,
  onBlur,
  onClick,
  onFocus,
  errorText,
  isDisabled,
  registerProperty,
  id,
  classNames,
  flagValue,
  setAsFormValue,
  setState,
  returnValue = "code",
  noMargin = false,
  name,
  isRequired = false,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [generatedId, setGeneratedId] = useState<any>();
  const [inputValue, setInputValue] = useState<string>("");

  const [flag, setFlag] = useState<ICountries | undefined>();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const myRef: any = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleFlagChange = (value: ICountries) => {
    setFlag(value);
    setAsFormValue && setAsFormValue(name, value ? value.code : undefined);
    setState && setState(returnValue === "object" ? value : value[returnValue]);
    setDropdownOpen(false);
    myRef.current.value = "";
  };

  useEffect(() => {
    countryData.map((item) => {
      if (item.code === flagValue) {
        setFlag(item);
      }
    });
  }, [flagValue]);

  useEffect(() => {
    setGeneratedId(generateId());
  }, []);

  const handleClick = (
    e: MouseEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onClick && onClick(e);
  };

  const handleFocus = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsFocused(false);
    onBlur && onBlur(e);
    registerProperty && registerProperty.onBlur(e);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { value } = e.target;

    // removing characters from value
    value = value.replace(/\D/g, "");

    myRef.current.value = value.slice(-(flag?.maxLength || 10));

    setInputValue(value);
    onChange && onChange(e);
    registerProperty && registerProperty.onChange(e);
  };

  useLayoutEffect(() => {
    if (registerProperty && myRef?.current?.value) {
      setInputValue(myRef.current.value);
    }
  }, [registerProperty]);

  return (
    <div
      className={`relative ${classNames ? classNames : ""} ${
        isDisabled ? "opacity-50 cursor-not-allowed pointer-events-none" : ""
      } ${!noMargin ? "mt-5 mb-3" : ""}  min-w-[200px]`}
    >
      <div className="relative">
        <label
          htmlFor={
            id
              ? id
              : registerProperty?.name
              ? registerProperty.name
              : generatedId
          }
          className="block font-inter text-xs font-semibold text-neutral-500 mb-2"
        >
          {t(`${label}`)}
          {isRequired ? (
            <span className="ml-1 text-danger-500 font-inter text-[12px] font-semibold">
              *
            </span>
          ) : null}
        </label>
        {/* input start*/}

        <div
          className={` ${
            !errorText && !isFocused && inputValue
              ? "border-success-500"
              : errorText
              ? "border-danger-500"
              : isFocused
              ? "border-primary-500"
              : "border-neutral-200"
          } flex border  px-6 py-2 rounded-lg w-full items-center`}
        >
          <div className="border-r pr-3 border-neutral-200 ">
            <Popover open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <PopoverTrigger onClick={toggleDropdown}>
                <div className="min-w-[16px] cursor-pointer h-4 flex justify-center gap-2 items-center mr-2">
                  {flag && (
                    <>
                      <img
                        src={flag.flag}
                        alt={flag.iso}
                        className="w-[20px] rounded-sm h-full"
                      />
                      <Icon
                        name="expand_more"
                        variant="outlined"
                        className={`${
                          dropdownOpen ? "rotate-180" : ""
                        } text-base text-neutral-600`}
                      />
                      <h3 className="font-inter text-text-primary text-sm font-medium">
                        {flag.code}
                      </h3>
                    </>
                  )}
                </div>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <div className=" bg-white shadow-2xl rounded-lg">
                    <div className=" mt-1 w-full overflow-y-scroll no-scrollbar h-48 rounded-md bg-white border border-gray-400 shadow-lg">
                      {countryData.map((country) => (
                        <div
                          key={country.iso}
                          className={`flex items-center px-4 py-2 cursor-pointer hover:bg-primary-500 hover:text-white ${
                            flag === country ? "bg-gray-700 text-white" : ""
                          }`}
                          onClick={() => {
                            handleFlagChange(country);
                          }}
                        >
                          <img
                            src={country.flag}
                            alt={country.iso}
                            className="w-5 h-5 rounded-full mr-2"
                          />
                          <span>{country.iso}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div>
          <input
            type="tel"
            id={
              id
                ? id
                : registerProperty?.name
                ? registerProperty.name
                : generatedId
            }
            className={`relative px-2 w-full font-inter font-medium text-sm outline-none  placeholder:text-neutral-200 bg-transparent`}
            ref={(el) => {
              myRef.current = el;
              registerProperty && registerProperty.ref(el);
            }}
            name={registerProperty ? registerProperty.name : name}
            key={registerProperty}
            placeholder={placeholder ? t(placeholder) : ""}
            onClick={(e: MouseEvent<HTMLInputElement>) => handleClick(e)}
            onFocus={(e: ChangeEvent<HTMLInputElement>) => handleFocus(e)}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => handleBlur(e)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          />
        </div>
      </div>
      {errorText && (
        <h3 className="text-danger-500 text-xxs font-inter absolute mt-[5px] ">
          {errorText}
        </h3>
      )}
    </div>
  );
};

export default PhoneInput;
