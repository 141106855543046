import { useSearchParams } from "react-router-dom";

interface UIPagination {
  page: string | number;
  limit: string | number;
  defaultPagination: string;
}

export const defaultPagination = "?page=1&limit=10";

export const usePagination = (
  page: string | number = 1,
  limit: string | number = 10
): UIPagination => {
  const [searchParams] = useSearchParams();

  return {
    page: searchParams.get("page") || page,
    limit: searchParams.get("limit") || limit,
    defaultPagination: defaultPagination,
  };
};
