import { AbilityProvider } from "@acl/Can";
import AlertDialog from "@components/Confirmation/AlertDialog";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";
import InternetError from "@components/InternetError/InternetError";
import { ToastComponent } from "@components/ToastComponent";
import AlertProvider from "context/alert";
import AuthProvider from "context/auth";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "router";
import store from "store/store";

const App = () => {
  return (
    <Provider store={store}>
      <InternetError>
        <ErrorBoundary>
          <BrowserRouter>
            <AuthProvider>
              <AlertProvider AlertComponent={AlertDialog}>
                <AbilityProvider>
                  <Router />
                  <ToastComponent />
                </AbilityProvider>
              </AlertProvider>
            </AuthProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </InternetError>
    </Provider>
  );
};

export default App;
