import commonGenderImg from "@assets/images/commonGender.png";
import femaleAvatarImg1 from "@assets/images/femaleAvatar1.png";
import femaleAvatarImg2 from "@assets/images/femaleAvatar2.png";
import femaleAvatarImg3 from "@assets/images/femaleAvatar3.png";
import maleAvatarImg1 from "@assets/images/maleAvatar1.png";
import maleAvatarImg2 from "@assets/images/maleAvatar2.png";
import maleAvatarImg3 from "@assets/images/maleAvatar3.png";
import Image from "@components/Image/Image";
import { IEmployeeCard } from "@interfaces/employee.interface";
import { useState } from "react";
import { nameMapper, trimString } from "utils";
import { Constant } from "utils/constant";

type EmployeeCardProps = {
	index?: any;
	noPadding?: boolean;
	selected?: boolean;
	employeeData?: IEmployeeCard;
	onClick?: () => void;
	className?: string;
	trimCount?: number;
	nameClassName?: string;
};

const EmployeeCard = ({
	selected = false,
	noPadding = false,
	onClick,
	employeeData,
	className,
	// index = Math.floor(Math.random() * 100) + 1,
	index,
	trimCount = 18,
	nameClassName,
}: EmployeeCardProps) => {
	// to maintain random avatar with all profile sequence
	const [sequence] = useState(
		index ? index % 3 : Number(employeeData?.phone || 0) % 3
	);

	const maleAvatars = [maleAvatarImg1, maleAvatarImg2, maleAvatarImg3];
	const maleBG = ["warning", "primary", "success"];
	const femaleAvatars = [
		femaleAvatarImg1,
		femaleAvatarImg2,
		femaleAvatarImg3,
	];
	const femaleBG = ["success", "danger", "warning"];

	const maleRandomAvatars = () => {
		return maleAvatars[Math.floor(Math.random() * 2) + 1];
	};

	const femaleRandomAvatars = () => {
		return femaleAvatars[Math.floor(Math.random() * 2) + 1];
	};

	return (
		<div
			onClick={onClick && onClick}
			className={`${
				selected && "bg-secondary-50 sticky top-0 bottom-0"
			} ${
				!noPadding ? " py-3 px-6" : ""
			} flex items-center rounded-[6px] ${className ? className : ""}`}
		>
			{
				<Image
					noBorder
					src={
						employeeData?.file?.path
							? employeeData?.file?.path
							: employeeData?.gender === "Male"
							? maleAvatars[sequence]
							: employeeData?.gender === "Female"
							? femaleAvatars[sequence]
							: employeeData?.gender === null ||
							  employeeData?.gender === undefined
							? commonGenderImg
							: ""
					}
					className={`w-10 h-10  rounded overflow-hidden ${
						employeeData?.file?.path
							? ""
							: employeeData?.gender === "Male"
							? `p-[3px] bg-${maleBG[sequence]}-100`
							: employeeData?.gender === "Female"
							? `p-[3px] bg-${femaleBG[sequence]}-100`
							: employeeData?.gender === null ||
							  employeeData?.gender === undefined
							? `p-[3px] bg-neutral-200`
							: ""
					}`}
				/>
			}
			<div className={`${nameClassName ? nameClassName : ""} pl-3`}>
				<h4 className="m-0 p-0 font-inter text-sm font-semibold text-left text-dark-600">
					{employeeData?.first_name || employeeData?.last_name
						? trimString(nameMapper(employeeData), trimCount)
						: Constant.NO_DATA}
				</h4>
				<h5 className="m-0 p-0 font-inter text-[10px] text-left font-normal text-neutral-400">
					{employeeData?.designation || Constant.NO_DATA}
				</h5>
				<h5 className="font-inter text-[10px] font-normal text-left text-neutral-400">
					{employeeData?.employee_no || Constant.NO_DATA}
				</h5>
			</div>
		</div>
	);
};

export default EmployeeCard;
