interface IENV {
	ApiEndPoint: string | undefined;
	env: string | undefined;
}

const baseUrl = (): string => {
	if (import.meta.env.MODE === "development")
		return import.meta.env.VITE_BASE_URL;

	return `https://${window.location.hostname.split(".")[0]}.${
		import.meta.env.VITE_BASE_URL
	}`;
};

export const ENV: IENV = {
	ApiEndPoint: baseUrl(),
	env: import.meta.env.MODE,
};
