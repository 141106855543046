import commonGenderImg from "@assets/images/commonGender.png";
import femaleAvatarImg1 from "@assets/images/femaleAvatar1.png";
import femaleAvatarImg2 from "@assets/images/femaleAvatar2.png";
import femaleAvatarImg3 from "@assets/images/femaleAvatar3.png";
import maleAvatarImg1 from "@assets/images/maleAvatar1.png";
import maleAvatarImg2 from "@assets/images/maleAvatar2.png";
import maleAvatarImg3 from "@assets/images/maleAvatar3.png";
import Icon from "@components/Icon/Icon";
import Image from "@components/Image/Image";
import { LayoutContext } from "@layouts/AuthLayout";
import { AuthService } from "@services/apis/Auth.service";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { ToastService } from "@services/utils/toastr.service";
import { useConfirmAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@components/Button/Button";
import EmployeeCard from "@components/Card/EmployeeCard";
import {
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
} from "@components/Popover/Popover";

const Header = ({ show }: any) => {
	const { logout, isAuthenticated } = useAuth();
	const { showAlert } = useConfirmAlert();
	const { showSidebar, setShowSidebar } = useContext(LayoutContext);

	const systemSetting = LocalStorageService.get("systemSettings");

	const [selectedLng, setSelectedLng] = useState<string>(
		localStorage.getItem("i18nextLng") ||
			systemSetting.language.value ||
			"en"
	);
	const [userData] = useState(LocalStorageService.get("userData") || {});

	// const [sequence] = useState((Math.floor(Math.random() * 100) + 1) % 3);
	// to maintain random avatar with all profile sequence
	const [sequence] = useState(Number(userData?.phone || 0) % 3);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { i18n } = useTranslation();

	const maleAvatars = [maleAvatarImg1, maleAvatarImg2, maleAvatarImg3];
	const maleBG = ["secondary", "primary", "success"];
	const femaleAvatars = [
		femaleAvatarImg1,
		femaleAvatarImg2,
		femaleAvatarImg3,
	];
	const femaleBG = ["success", "danger", "warning"];

	const onLogout = async () => {
		showAlert({
			title: t("confirm_logout"),
			confirmMessage: t(`are_you_sure_you_want_to_logout`),
			confirmBtnText: t("yes"),
			alertType: "logout",
			async onConfirm() {
				await AuthService.logout()

					.catch((err) => {
						ToastService.error(err.message);
					})
					.finally(async () => {
						await logout();
					});
			},
		});
	};

	useEffect(() => {
		if (selectedLng) i18n.changeLanguage(selectedLng);
	}, [selectedLng, isAuthenticated]);

	return (
		<div className={`header z-50 w-full h-full ${show ? "" : "hidden"}`}>
			<div className="header-wrapper flex w-full h-full xs:px-2 px-6 py-3 bg-white">
				{/* TODO:: For responsive reference */}
				<div className="header-left xs:ml-2 h-full w-[150px] lg:hidden flex items-center">
					<button
						onClick={() => setShowSidebar(!showSidebar)}
						className="border-none mr-5 lg:hidden sm:block"
					>
						<Icon
							className={` animate-bounce-in ${
								showSidebar
									? "text-danger-500"
									: "text-primary-500"
							}`}
							name={showSidebar ? "close" : "menu"}
							variant="outlined"
						/>
					</button>
				</div>
				<div className="w-52 px-4 py-2 lg:flex xs:hidden sm:hidden flex items-center mr-auto shadow rounded-md">
					{/* <Input
            label="search"
            placeholder="Search Here"
          /> */}
					<Icon
						name="search"
						className="text-neutral-300 text-base"
					/>
					<span className="ml-3 text-neutral-300 text-sm font-medium">
						{t("search")}
					</span>
				</div>
				<div className="header-right basis-full relative  flex justify-end items-center">
					{/* //  select language part */}
					<div className=" border xs:hidden lg:flex border-neutral-50 px-3 py-[2px] mr-6 bg-white rounded-lg shadow-sm  items-center cursor-pointer">
						<Icon
							className="text-base  text-neutral-500"
							name="language"
						/>

						<select
							className={`border-0 outline-none  text-secondary-text text-xs p-2 text-neutral-500  bg-white cursor-pointer`}
							// className="border-0 outline-none  text-secondary-text text-xs p-2 bg-white"
							onChange={(e) => {
								setSelectedLng(e.target.value);
								window.location.reload();
							}}
							defaultValue={selectedLng}
						>
							<option className="p-1 text-neutral-500" value="en">
								English
							</option>
							<option className="p-1 text-neutral-500" value="bn">
								বাংলা
							</option>
							<option className="p-1 text-neutral-500" value="ar">
								عربي
							</option>
						</select>
					</div>

					<div className=" p-2 flex xs:hidden items-end relative text-neutral-400 border border-neutral-50 rounded-lg cursor-pointer">
						<Icon
							name="notifications"
							variant="outlined"
							className="text-base text-primary-500"
						/>
						{/* <span className="bg-red-500 w-3 h-3 left-[13px] border-2 border-white absolute rounded-full"></span> */}
					</div>
					<Popover>
						<PopoverTrigger>
							<div className="flex items-center justify-start space-x-3 pr-3">
								<EmployeeCard employeeData={userData} />
								<Icon
									className="text-lg cursor-pointer text-neutral-400"
									name="expand_more"
									variant="outlined"
								/>
							</div>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverBody>
								<div className="flex flex-col p-10 rounded-xl shadow-lg ">
									<div
										className={`${
											userData?.file?.path
												? "bg-success-100"
												: userData?.gender === "Male"
												? `p-[3px] bg-${maleBG[sequence]}-100`
												: userData?.gender === "Female"
												? `p-[3px] bg-${femaleBG[sequence]}-100`
												: userData?.gender === null ||
												  userData?.gender === undefined
												? `p-[3px] bg-neutral-200`
												: ""
										} flex justify-center items-center -top-7 mx-auto z-10 rounded-[10px] w-[100px] h-[100px]`}
									>
										<div className="relative">
											<Image
												src={
													userData?.file?.path
														? userData?.file?.path
														: userData?.gender ===
														  "Male"
														? maleAvatars[sequence]
														: userData?.gender ===
														  "Female"
														? femaleAvatars[
																sequence
														  ]
														: userData?.gender ===
																null ||
														  userData?.gender ===
																undefined
														? commonGenderImg
														: ""
												}
												className="w-[100px] h-[100px]"
												noBorder
											/>
											<Icon
												className=" flex items-center justify-center absolute w-[26px] text-lg text-neutral-400 h-[26px] rounded-full bg-white shadow-md -right-[8px] -bottom-[6px] cursor-pointer"
												name="edit"
												variant="outlined"
											/>
										</div>
									</div>
									<div className="flex flex-col justify-center gap-2 items-center mt-3">
										<h3 className="font-inter text-base font-bold text-neutral-700 mb-0">
											{userData?.name}
										</h3>
										<h3 className="font-inter text-xs font-bold text-neutral-500 ">
											{userData?.designation}
										</h3>
										<div className=" text-center">
											<h4 className="font-inter text-xs text-neutral-500 font-normal mb- leading-none">
												{userData?.email || ""}
											</h4>
										</div>
										<div className="flex mt-4">
											<Button
												onClick={() =>
													navigate(
														"settings/profile/general"
													)
												}
												variant="outlined"
												size="sm"
												className="mr-4"
												color="dark"
											>
												<div className="flex item-center">
													<Icon
														className="text-sm leading-tight text-neutral-500 mr-2"
														name="person"
													/>
													<span className="text-sm leading-tight">
														{t("profile")}
													</span>
												</div>
											</Button>
											<Button
												onClick={() => onLogout()}
												variant="outlined"
												size="sm"
												color="dark"
											>
												<div className="flex item-center">
													<Icon
														className="text-sm leading-tight text-neutral-500 mr-2"
														name="logout"
													/>
													<span className="text-sm leading-tight">
														{t("logout")}
													</span>
												</div>
											</Button>
										</div>

										<div className="text-[10px] text-neutral-400 flex items-center space-x-4 justify-center mt-2">
											<span>
												{t("privacy_of_policy")}
											</span>
											<div className="w-[4px] h-[4px] bg-neutral-400 rounded-full">
												.
											</div>
											<span>{t("terms_of_service")}</span>
										</div>
									</div>
								</div>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</div>
			</div>
		</div>
	);
};

export default Header;
