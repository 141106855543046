import { defaultEmployeeAcl, employeeACL } from "@acl/modules/employee.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	EMPLOYEES,
	EMPLOYEE_FORM,
	GROUPS,
	PEERS,
	TEAMS,
} from "./path-name.route";

export const employeeRoutes: IRouteList[] = [
	{
		path: EMPLOYEES + "/*",
		component: lazy(() => import("@pages/EmployeesModule/EmployeeModule")),
		children: [
			{
				path: "list",
				component: lazy(
					() => import("@pages/EmployeesModule/Employees/Employees")
				),
				meta: {
					action: employeeACL.employee.general.getAll.action,
					resource: employeeACL.employee.general.getAll.resource,
				},
			},
			{
				path: PEERS,
				component: lazy(
					() => import("@pages/EmployeesModule/Peer/Peer")
				),
				meta: {
					action: defaultEmployeeAcl.action,
					resource: defaultEmployeeAcl.resource,
				},
			},
			{
				path: TEAMS,
				component: lazy(
					() => import("@pages/EmployeesModule/Team/Team")
				),
				meta: {
					action: defaultEmployeeAcl.action,
					resource: defaultEmployeeAcl.resource,
				},
			},
			{
				path: GROUPS,
				component: lazy(
					() => import("@pages/EmployeesModule/Group/Group")
				),
				meta: {
					action: defaultEmployeeAcl.action,
					resource: defaultEmployeeAcl.resource,
				},
			},
		],
	},
	{
		path: EMPLOYEES + "/details",
		component: lazy(
			() =>
				import(
					"@pages/EmployeesModule/EmployeesDetails/EmployeeDetails"
				)
		),
		meta: [employeeACL.employee.general.details, defaultEmployeeAcl],
	},
	// {
	// 	path: "employee/details",
	// 	component: lazy(
	// 		() =>
	// 			import(
	// 				"@pages/EmployeesModule/EmployeesDetails/EmployeeDetails"
	// 			)
	// 	),
	// 	meta: {
	// 		action: defaultEmployeeAcl.action,
	// 		resource: defaultEmployeeAcl.resource,
	// 	},
	// },

	{
		path: EMPLOYEE_FORM,
		component: lazy(
			() => import("@pages/EmployeesModule/Forms/StepLayout")
		),
		meta: {
			action: employeeACL.employee.general.form.action,
			resource: employeeACL.employee.general.form.resource,
		},
		children: [
			{ path: "*", redirect: "general" },
			{
				path: "general",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/GeneralForm"
						)
				),
				meta: employeeACL.employee.general.form,
				isIndex: true,
			},
			{
				path: "personal",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Personal/Personal"
						)
				),
				meta: employeeACL.employee.personal.form,
			},
			{
				path: "family",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Family/Family"
						)
				),
				meta: employeeACL.employee.family.form,
			},
			{
				path: "bank",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/BankInfo/Bank"
						)
				),
				meta: employeeACL.employee.bank.form,
			},
			{
				path: "disciplinary-actions",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/DisciplinaryActions/DisciplinaryAction"
						)
				),
				meta: employeeACL.employee.actions.form,
			},
			{
				path: "achievements",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Achievements/Achievements"
						)
				),
				meta: employeeACL.employee.achievement.form,
			},
			{
				path: "address",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Addresses/EmployeeAddress"
						)
				),
				meta: employeeACL.employee.address.form,
			},
			{
				path: "educational",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Educations/Educations"
						)
				),
				meta: employeeACL.employee.education.form,
			},
			{
				path: "employment",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Employments/EmploymentInfo"
						)
				),
				meta: employeeACL.employee.employment.form,
			},
			{
				path: "training",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Trainings/Training"
						)
				),
				meta: employeeACL.employee.training.form,
			},
			{
				path: "service-books",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/ServiceBooks/ServiceBook"
						)
				),
				meta: employeeACL.employee.serviceBook.form,
			},
			{
				path: "employment-history",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/EmploymentHistories/EmploymentHistory"
						)
				),
				meta: employeeACL.employee.history.form,
			},
			{
				path: "documents",
				component: lazy(
					() =>
						import(
							"@pages/EmployeesModule/Forms/Screens/Documents/Documents"
						)
				),
				meta: employeeACL.employee.document.form,
			},
		],
	},
];
