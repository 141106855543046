import Logo from "@assets/images/rysenova-logo-v8_720.png";
import LogoIcon from "@assets/menuIcons/logo-square.png";
import SettingSvg from "@assets/menuIcons/settings.svg";
import { LayoutContext } from "@layouts/AuthLayout";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCOUNT_SETTINGS } from "router/path-name.route";
import NavLink from "./NavLink";
import "./Sidebar.css";
import { INavLink, navLinks } from "./nav-links";

interface ISidebar {
  show: boolean;
  expandSidebar: boolean;
  setExpandSidebar: Function;
}

const Sidebar = ({ show, expandSidebar, setExpandSidebar }: ISidebar) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showSidebar, setShowSidebar } = useContext(LayoutContext);

  const { t } = useTranslation();

  const [route, setRoute] = useState<string>("general");
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (pathname.split("/")[1] === "settings") {
      setRoute("settings");
    } else {
      setRoute("general");
    }
  }, [pathname]);

  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <aside
        id="sidebar-multi-level-sidebar"
        className={`xs:w-72 lg:w-auto h-full relative transition-transform -translate-x-full  sm:translate-x-0 xs:translate-x-0 ${
          show ? "" : "hidden"
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full py-2 px-4 overflow-y-auto bg-primary-900">
          <div
            className={`py-3 px-2 mb-[16px] ${
              expandSidebar ? "w-[200px]" : ""
            }`}
          >
            <div
              className={`${
                expandSidebar ? "w-[152px] mx-auto" : "flex justify-center"
              } h-[25px]`}
            >
              <img
                src={expandSidebar ? Logo : LogoIcon}
                alt=""
                className={expandSidebar ? "w-full" : "w-[25px]"}
              />
            </div>
          </div>
          <div className={` flex flex-col justify-between `}>
            <ul className="space-y-2 font-medium ">
              {navLinks[route].map((nav: INavLink, index: number) => {
                return (
                  <NavLink
                    onClick={() => setShowSidebar(false)}
                    navLinks={nav}
                    key={index}
                    index={index}
                    expandSidebar={expandSidebar}
                  />
                );
              })}
            </ul>
            {route === "general" ? (
              <div
                className={
                  screenHeight < 700
                    ? "flex items-center px-6 py-3  text-white cursor-pointer mt-auto"
                    : "absolute bottom-4 flex items-center  px-6 py-3  text-white cursor-pointer mt-auto"
                }
                onClick={() => navigate(ACCOUNT_SETTINGS)}
              >
                {/* <Icon
                  name="settings"
                  variant="outlined"
                  className="text-base"
                /> */}
                <img src={SettingSvg} alt="" className="w-[18px] h-[18px]" />

                <span
                  className={`ml-3 text-sm font-normal leading-[1px] ${
                    expandSidebar ? "" : "lg:hidden block"
                  }`}
                >
                  {t("settings")}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
