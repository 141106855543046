import { t } from "i18next";
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from "react";

interface AlertOptions {
  title: ReactNode;
  confirmMessage: ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
  alertType?:
    | "delete"
    | "pdf"
    | "wait"
    | "error"
    | "warning"
    | "logout"
    | "info"
    | "cancel";
  onConfirm(): Promise<void> | void;
  onClose?: () => void;
}

const AlertContext = createContext<{
  showAlert(opts: AlertOptions): void;
} | null>(null);

/**
 * Any AlertDialog component used with AlertProvider should use these props
 */
export type AlertComponentProps = {
  open: boolean;
  message: ReactNode;
  title: ReactNode;
  onClose(): void;
  onConfirm(): Promise<void> | void;
  confirming?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  alertType?:
    | "delete"
    | "pdf"
    | "wait"
    | "error"
    | "warning"
    | "logout"
    | "info"
    | "cancel";
};

export type AlertProviderProps = {
  AlertComponent: React.ComponentType<AlertComponentProps>;
} & PropsWithChildren;

const AlertProvider = ({ AlertComponent, children }: AlertProviderProps) => {
  const [shown, setShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultOptions: AlertOptions = {
    title: "Are you sure?",
    confirmMessage: "Regret deleting this ? This can not be redo again",
    confirmBtnText: "delete",
    cancelBtnText: "cancel",
    alertType: "delete",
    async onConfirm() {
      setShown(false);
    },
    async onClose() {
      setShown(false);
    },
  };
  const [alertOptions, setAlertOptions] =
    useState<AlertOptions>(defaultOptions);

  const showAlert = (opts?: Partial<AlertOptions>) => {
    setShown(true);
    setAlertOptions({
      confirmMessage: opts?.confirmMessage ?? defaultOptions.confirmMessage,
      onConfirm: opts?.onConfirm ?? defaultOptions.onConfirm,
      onClose: opts?.onClose ?? defaultOptions.onClose,
      title: opts?.title ?? defaultOptions.title,
      confirmBtnText: opts?.confirmBtnText ?? t(defaultOptions.confirmBtnText),
      cancelBtnText: opts?.cancelBtnText ?? t(defaultOptions.cancelBtnText),
      alertType: opts?.alertType ?? defaultOptions.alertType,
    });
  };
  const onClose = async () => {
    setShown(false);
    alertOptions.onClose && alertOptions.onClose();
  };

  const onConfirm = async () => {
    setLoading(true);
    alertOptions.onConfirm && (await alertOptions.onConfirm());
    setLoading(false);
    setShown(false);
  };
  return (
    <AlertContext.Provider value={{ showAlert }}>
      <AlertComponent
        open={shown}
        onClose={onClose}
        onConfirm={onConfirm}
        message={alertOptions.confirmMessage}
        title={alertOptions.title}
        confirming={loading}
        confirmBtnText={alertOptions.confirmBtnText}
        cancelBtnText={alertOptions.cancelBtnText}
        alertType={alertOptions.alertType}
      />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;

export const useConfirmAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("Please Use AlertProvider in parent component.");
  }

  return context;
};
