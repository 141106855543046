import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthService } from "@services/apis/Auth.service";
import { LocalStorageService } from "@services/utils/localsStorage.service";

const userData: any = LocalStorageService.get("user_data");

interface IUserInfo {
  system_config: any;
  financial_sessions: any;
  account_policies: any;
}

const initialState: IUserInfo = {
  system_config: { data: { name: "Aminul Islam" } },
  financial_sessions: [],
  account_policies: {},
};

export const fetchCommonData = createAsyncThunk("user/commonData", () => {
  const response = AuthService.loginInfo();
  AuthService.loginInfo().then(({ payload }) => console.log(payload));

  // console.log(object);
  return response;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCommonData.fulfilled, (state, action) => {
      // Add user to the state array

      state = action.payload.payload;
      return state;
    });
  },
});

export default userSlice.reducer;
