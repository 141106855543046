import Cancel from "@assets/alertIcons/cancel.png";
import Error from "@assets/alertIcons/error.png";
import Exclamation from "@assets/alertIcons/exclamation.png";
import Info from "@assets/alertIcons/info.png";
import Loading from "@assets/alertIcons/loading.png";
import Logout from "@assets/alertIcons/logout.png";
import Trash from "@assets/alertIcons/trash.png";
import Warning from "@assets/alertIcons/warning.png";
import Alert from "@components/Alert/Alert";
import { ButtonLoader } from "@services/utils/preloader.service";
import { AlertComponentProps } from "context/alert";

const AlertDialog = ({
  title,
  message,
  onClose,
  open,
  onConfirm,
  confirming,
  confirmBtnText,
  cancelBtnText,
  alertType,
}: AlertComponentProps) => {
  const useImageSwitchCase = (type: string) => {
    switch (type) {
      case "logout":
        return Logout;

      case "pdf":
        return Loading;

      case "wait":
        return Exclamation;

      case "error":
        return Error;

      case "warning":
        return Warning;

      case "info":
        return Info;

      case "cancel":
        return Cancel;

      default:
        return Trash;
    }
  };

  return (
    <>
      <Alert backDrop={true} setShow={onClose} show={open} showBlur>
        <div className="w-full h-full py-10 px-6 flex flex-col justify-center items-center">
          <img src={useImageSwitchCase(alertType)} alt="" />

          <h3 className="mt-6 font-inter text-text-primary text-base font-semibold">
            {title}
          </h3>
          <h5 className="font-inter text-base font-normal text-text-primary my-4">
            {message}
          </h5>
          <div className=" flex justify-center items-center gap-3">
            <button
              onClick={() => onClose()}
              className="font-inter font-medium text-base py-2 px-4  border  border-neutral-200 text-text-secondary rounded-md "
              disabled={confirming}
            >
              {cancelBtnText}
            </button>
            <button
              onClick={() => onConfirm()}
              className="font-inter font-medium text-base py-2 px-4 bg-primary-500 rounded-md text-white"
              disabled={confirming}
            >
              {confirming ? <ButtonLoader /> : confirmBtnText}
            </button>
          </div>
        </div>
      </Alert>
    </>
  );
};
export default AlertDialog;
