import FourZeroOne from "@pages/Authentication/401";
import FourZeroThree from "@pages/Authentication/403";
import FourZeroFour from "@pages/Authentication/404";
import FourZeroFive from "@pages/Authentication/405";
import FiveZeroThree from "@pages/Authentication/503";
import EmailSentSuccess from "@pages/Authentication/EmailSentSuccess";
import EmailVerification from "@pages/Authentication/EmailVerification/EmailVerification";
import GlobalVerification from "@pages/Authentication/GlobalVerification";
import NoDataFound from "@pages/Authentication/NoDataFound";
import OrganizationNotFound from "@pages/Authentication/OrganizationNotFound";
import PageUnderConstruction from "@pages/Authentication/PageUnderConstruction";
import PasswordUpdateSuccess from "@pages/Authentication/PasswordUpdateSuccess";
import Unauthorized from "@pages/Authentication/Unauthorized";
import { TopProgressCom } from "@services/utils/topProgress.service";
import { useAuth } from "context/auth";
import { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { IRouteList, authRouteList } from "./list.route";
import { LOGIN } from "./path-name.route";

export const Router = () => {
  const { isUnauthorizedOrd, isAuthenticated } = useAuth();

  if (isUnauthorizedOrd) return <OrganizationNotFound />;

  return (
    <Routes>
      <Route path="/Unauthorized" element={<Unauthorized />} />
      <Route path="/email-sent-success" element={<EmailSentSuccess />} />
      <Route path="/401" element={<FourZeroOne />} />
      <Route path="/403" element={<FourZeroThree />} />
      <Route path="/404" element={<FourZeroFour />} />
      <Route path="/405" element={<FourZeroFive />} />
      <Route path="/503" element={<FiveZeroThree />} />
      <Route path="/no-data-found" element={<NoDataFound />} />
      <Route path="/org-notfound" element={<OrganizationNotFound />} />
      <Route path="/verify-email" element={<EmailVerification />} />
      <Route
        path="/page-under-construction"
        element={<PageUnderConstruction />}
      />
      <Route
        path="/password-update-success"
        element={<PasswordUpdateSuccess />}
      />
      <Route path="/verification" element={<GlobalVerification />} />
      {isAuthenticated ? (
        <>
          <Route path="/*" element={<PrivateRoutes />} />
        </>
      ) : (
        <>
          <Route index element={<Navigate to={LOGIN} />} />
          {authRouteList.map((route: IRouteList, index: number) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={route?.component ? <route.component /> : <Outlet />}
              >
                {route?.children?.map(
                  (childRoute: IRouteList, cIndex: number) => {
                    return (
                      <Route
                        key={cIndex}
                        path={childRoute.path}
                        element={
                          <Suspense fallback={<TopProgressCom />}>
                            {childRoute?.component ? (
                              <childRoute.component />
                            ) : (
                              <Outlet />
                            )}
                          </Suspense>
                        }
                      />
                    );
                  }
                )}
              </Route>
            );
          })}
          <Route path="*" element={<Navigate to={LOGIN} />} />
        </>
      )}
      {/* <Route path="*" element={<NotFound />} /> */}
      {/* <Route path="*" element={<FourZeroFour />} /> */}
    </Routes>
  );
};
