export class Constant {
	public static readonly ACTIVE: string = "Active";
	public static readonly INACTIVE: string = "Inactive";
	public static readonly SUSPENDED: string = "Suspended";
	public static readonly ARCHIVED: string = "Archived";
	public static readonly MONTH: string = "Month";
	public static readonly MONTHLY: string = "Monthly";
	public static readonly WEEK: string = "Week";
	public static readonly WEEKLY: string = "Weekly";
	public static readonly WEEKEND: string = "Weekend";
	public static readonly EMPLOYEE = "Employee";
	public static readonly ADMIN = "Admin";
	public static readonly PAYABLE = "Payable";
	public static readonly LOGO_DEFAULT_EXTENSION: string[] = [
		"png",
		"jpg",
		"jpeg",
	];
	public static readonly NO_DATA = "--";

	public static readonly PAYABLE_TYPE_CASH = "CASH";
	public static readonly PAYABLE_TYPE_BANK = "BANK";
	public static readonly PAYABLE_TYPE_DIGITAL_BANKING = "DIGITAL_BANKING";
	public static readonly EMPLOYEE_CREATION_MANUAL_TYPE = "Manual";
	public static readonly EMPLOYEE_CREATION_AUTOMATED_TYPE = "Automated";

	// for education results---
	public static readonly FIRST_CLASS = "1st Class";
	public static readonly SECOND_CLASS = "2nd Class";
	public static readonly THIRD_CLASS = "3rd Class";
	public static readonly OUT_OF_4 = "Out of 4";
	public static readonly OUT_OF_5 = "Out of 5";

	// for css styles---
	public static readonly DRAWER_WIDTH_FOR_EMPLOY_CARD =
		"w-[80%] md: w-[70%] lg:w-[550px]";
	public static readonly FULFILLED = "fulfilled";
}
